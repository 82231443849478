<template>
  <v-autocomplete
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    color="tertiary"
    :label="$t('hr.projects.project')"
    item-value="id"
    :menu-props="{ offsetY: true, overflowY: true }"
    :loading="loading || fetching"
    :items="items"
    :clearable="clearable && !readonly"
    :readonly="readonly"
    @input="$emit('input', $event)"
  >
    <template v-slot:selection="{ item, index }">
      <v-avatar size="26" :color="item.color" class="mr-1">
        <span class="body-2 font-weight-regular white--text">
          {{ item.name.substring(0, 2).toUpperCase() }}
        </span>
      </v-avatar>
      <span>
        {{ item.name }}
      </span>
    </template>
    <template v-slot:item="{ item, tile }">
      <v-list-item-avatar size="30" :color="item.color" class="headline font-weight-light white--text">
        <span class="caption">
          {{ item.name.substring(0, 2).toUpperCase() }}
        </span>
      </v-list-item-avatar>
      <v-list-item-content class="ml-2">
        <v-list-item-title class="body-1">
          {{ item.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{
            $tc("hr.projects.employee_count_message", item.employees.length, {
              count: item.employees.length
            })
          }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-14 11:31:37
   * @modify date 2021-01-14 11:31:37
   * @desc ProjectPicker
   */
  import gql from "graphql-tag";

  export default {
    name: "ProjectPicker",
    inheritAttrs: false,
    props: {
      value: String,
      loading: Boolean,
      clearable: {
        type: Boolean,
        default: true
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    data: vm => ({
      fetching: false,
      items: []
    }),
    methods: {
      fetchItems() {
        const options = {
          query: gql`
            {
              projects {
                id
                color
                name
                employees {
                  id
                }
              }
            }
          `,
          fetchPolicy: "no-cache"
        };
        this.fetching = true;
        this.$apollo
          .query(options)
          .then(({ data: { projects, error }, errors }) => {
            if (!error && !errors) {
              this.items = projects;
            }
          })
          .catch(console.error)
          .finally(() => (this.fetching = false));
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style lang="scss" scoped></style>
